<template>
  <div class="PageTop">
    <div class="topLogo" />
    <div class="topCenter" :type="type"/>
  </div>
</template>

<script>
export default {
  name: "PageTop",
  props:{
    type: {
      required: false,
      type: String,
      default: '1',
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";
.PageTop {
  position: relative;
  .topLogo{
    width: 364px;
    height: 89px;
    @include backgroundMix;
    background-image: url("../assets/images/dwlogo.png");
    position: absolute;
    left: 10px;
    top: 10px;
    @media(max-width:  1280px){
      width: 243px;
      height: 59px;
      top: 7px;
      left: 7px;
    }
  }
  .topCenter{
    width: 1394px;
    height: 63px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    @media(max-width:  1280px){
      width: 929px;
      height: 42px;
    }
  }


  .topCenter[type="1"]{
    @include backgroundMix;
    background-image: url("../assets/images/top_line.png");
  }

  .topCenter[type="2"]{
    @include backgroundMix;
    background-image: url("../assets/images/kemutip.png");
  }

  .topCenter[type="3"]{
    @include backgroundMix;
    background-image: url("../assets/images/affects/affect_tip.png");
  }

  .topCenter[type="4"]{
    @include backgroundMix;
    background-image: url("../assets/images/affects/affect_tip.png");
  }

  .topCenter[type="5"]{
    @include backgroundMix;
    background-image: url("../assets/images/student/student_tip.png");
  }
}
</style>
