<template>
  <div class="Clock">
    <img src="../assets/images/zhong.png" alt="">
    <span>
      {{moment().format('YYYY-MM-DD HH:mm')}}
    </span>
  </div>
</template>

<script>
import moment from  'moment';
export default {
  name: "Clock",
  data() {
    return {
      moment,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
.Clock {
  font-size: 22px;
  color: #FFF;
  font-family: MicrosoftYaHei;
  display: flex;
  align-items: center;
  position: absolute;
  top: 40px;
  right: 30px;
  z-index: 99999;
  @media (max-width: 1280px) {
    font-size: 15px;
    top: 27px;
    right: 21px;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    @media (max-width: 1280px) {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
</style>
