<template>
  <div class="Subjects">
    <page-top type="2"/>
    <clock/>
    <div class="subjectTip"></div>
    <div class="subjectsNum">{{ projectNum}}个科目</div>
    <div v-for="(i, k) in projects" :key="k">
      <subject-card :index="k" :str="i"/>
    </div>
  </div>
</template>

<script>
import PageTop from "../components/PageTop";
import Clock from "../components/Clock";
import {onMounted, onUnmounted, reactive, toRefs} from "vue";
import service from "../utils/axios";
import SubjectCard from "../components/Subject/SubjectCard";
import {changePage} from "../utils/getPageTime";
import {useRouter} from "vue-router";

export default {
  name: "Subjects",
  components: {SubjectCard, Clock, PageTop},
  data() {
    return {}
  },
  setup() {
    let router = useRouter();
    let state = reactive({
      projects: [],
      projectNum: 0,
      timeout: null,
    })


    const getData = async () => {
      let data = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/project')
      state.projects = data.projectList.splice(0, 31) || []
      state.projectNum = data.projectNum

      state.timeout = await changePage(router,'科目')
    }

    onMounted(()=>{
      getData()
    })
    onUnmounted(()=>{
      state.timeout && clearTimeout(state.timeout)
    })

    return {
      ...toRefs(state),
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";
.Subjects {
  .subjectTip{
    width: 399px;
    height: 143px;
    @include backgroundMix;
    background-image: url("../assets/images/kemucolor.png");
    position: absolute;
    top: 103px;
    left: 50%;
    transform: translateX(-50%);
    @media(max-width:  1280px){
      width: 266px;
      height: 95px;
      top: 69px;
    }
  }
}
</style>
